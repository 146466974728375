import logo from "./logo.png";
import luis from "./luis.jpg";
import "./App.css";
import Spanish from "./Spanish";
import English from "./English";

function App() {
  return (
    <div className="App">
      <header className="header">
        <img src={logo} alt="Salomon Coello" />
        <div className="bio">
        <Spanish/>
        </div>
        <p><a href="mailto:salomoncoellomx@gmail.com">salomoncoellomx@gmail.com</a></p>
        <p>CartonPlay®: <a href="https://cartonplay.mx/">cartonplay.mx</a></p>
        <img src={luis} alt="Salomon Coello" />
      </header>
    </div>
  );
}

export default App;
