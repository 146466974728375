import React from "react";

function Spanish() {
  return (
    <div className="language">
      <p>
          <b>Salomón Coello</b> es un talentoso diseñador industrial mexicano, con una sólida formación en áreas
como publicidad y marketing, ha dejado su huella en reconocidas empresas a nivel global, entre las que se
incluyen: Young &amp; Rubicam, 3M, Mccann Erickson y HH Global. A lo largo de su carrera, ha contribuido al
éxito de algunas de las marcas más valiosas del mercado.
        </p>
        <p>
        Apasionado por el cine, la música electrónica y amante de las mascotas, <b>Coello</b> es una persona
emprendedora y en 2009, fundó Sunlight, una agencia especializada en servicios de publicidad. Su
entusiasmo ha perdurado llevándolo a emprender en sus tiempos libres, debido a sus responsabilidades
trabajando para grandes marcas.
        </p>
        <p>
        En 2013 da vida al innovador concepto de CartonPlay®, una marca enfocada al diseño de mobiliario
lúdico sustentable fabricado de cartón corrugado reciclado, en 2014 participó en Hábitat Expo donde presentó
la primera colección diseñada junto con 4 colaboradores entusiastas. Después de esta afortunada experiencia
Coello encontró la oportunidad de trabajar para las mascotas, lo cuál ha hecho desde entonces, diseñando y
desarrollando varias colecciones, obteniendo desde entonces varios reconocimientos:
        </p>
        <ul>
<li>Ganador de Plata Concurso de la revista a! Diseño 2023 categoría mobiliario. (Sofá Agata)</li>
<li>Premio Nacional FedEx Crece tu Pyme 2021 (Emprendimiento CartonPlay®)</li>
<li>Finalista Red Dot Award 2021 categoría mobiliario. (Cat-Cell Furniture Concept)</li>
<li>Finalista The index Project 2021 categoría hogar. (Modular Cat Entertainment Furniture)</li>
<li>Finalista Concurso de la Revista a! Diseño 2020 categoría mobiliario. (Cat-Cell)</li>
<li>Finalista Concurso de la Revista a! Diseño 2019 categoría mobiliario. (Colección Bankito)</li>
<li>Ganador de Plata en el Concurso de la Revista a! Diseño 2019 categoría mobiliario. (Colección Raketa)</li>
        </ul>
        <p>
        Gracias a su experiencia como director de arte e interiorista, sus colecciones mantienen un equilibrio
con la decoración del hogar, lo que permite una conexión inmediata con los amantes de mascotas. Desde
hace muchos años, las mascotas se han convertido en parte de la familia, nos dan compañía, ayuda
psicológica y sobre todo mucha felicidad, porque no consentirlos y cuidarlos como mejor se merecen?.
        </p>
        <p>
          La inspiración de la colección <b>¨Artemisa¨</b> surgió del concepto <i>Cat-Cell</i> desarrollado en 2019. Sistema Patentado en 2021
como: <i>¨ Mueble Escalable para Mascotas con Mecanismo Rotativo¨ .</i>
        </p>
        <p>El estilo de <b>Coello</b> es contemporáneo y se distingue por plasmar el matrimonio entre forma y función, sin descuidar la
estética ocupada en satisfacer el alma y la utilidad, enfocada al juego y comodidad de las mascotas. Su reciente colección
¨Artemisa¨ está integrada por 6 modelos: Agata, Aria, Astra y Afrodita, más 2 versiones para exteriores forman parte de la
familia. Para su fabricación se utiliza principalmente la madera y procesos amigables al medio ambiente, para no olvidarnos
de la madre tierra y su protagonismo en la vida.</p>
<p>Su reciente colección colección <b>¨Artemisa¨</b> se desarrolló para consentir a gatos y perros pequeños con una gama de
muebles y versiones que pudieran disfrutar al máximo, que fueran innovadores, divertidos, resistentes, estéticos y cómodos.
Su estilo contemporáneo se adapta fácilmente en armonía con el entorno, para satisfacer los gustos más exquisitos y
exigentes, inspirados en la naturaleza a través de formas orgánicas, llevando su ejecución hacia tonos cálidos y superficies
suaves al tacto.</p>
<p>Su obra escultórica <b>¨Afrodita¨</b> simboliza la emoción, la ternura y el amor, mediante la unión de sentimientos encontrados,
la pureza del espíritu libre y aventurero. Los instintos curiosos inagotables, llenos de energía y vitalidad se sumergen en sus
formas florales que prometen satisfacción única, que interactúan mediante sueños perdurables de alegría, albergando en su
espacio tantas almas como sea posible.</p>
    </div>
  );
}

export default Spanish;
